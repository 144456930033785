/* Homepage styles */
.herosection {
  background: url(../assets/hero.jpg);
  background-repeat: no-repeat;
  background-size: 100%;
  background-clip: padding-box;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.herosection h1 {
  width: 600px;
  font-size: 70px;
  padding-left: 30px;
  color: #fff;
  text-align: center;
}

.herosection a {
  /* padding-left: 250px; */
  color: rgb(14, 62, 219);
  text-align: center;
  width: 250px;
  align-self: center;
  margin-right: 750px;
  margin-top: 30px;
  padding: 20px;
  border-radius: 5px;
  font-weight: 900;
  background: #fff;
  text-decoration: none;
  font-size: 30px;
  /* display: flex; */
  justify-content: center;
}

.herosection a:hover {
  animation: button 2s ease-in-out forwards;
}

@keyframes button {
  from {
    transform: translateX(0px);
  }

  to {
    background: blue;
    color: #fff;
  }
}

/* Services Page styles */

.services__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  margin-top: 24px;
  width: 80%;
  margin: auto auto;
}

.services__container a {
  text-decoration: none;
  color: #000;
}

.services__container h1 {
  color: blue;
  margin-bottom: 16px;
  font-size: 16px;
}

.services {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 32px;
  padding: 16px;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.service {
  width: 350px;
  height: 250px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  grid-gap: 16px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
  background-color: #fff;
  padding: 8px;
  border-radius: 4px;
}

.service span {
  font-size: 45px;
  font-weight: 900;
}

.service:hover {
  animation-name: effect;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  color: white;
  animation-timing-function: ease-in-out;
}

@keyframes effect {
  from {
    transform: translateY(0px);
  }

  to {
    transform: translateY(-10px);
    background: rgba(49, 49, 230);
    color: #fff;
  }
}

.service p {
  line-height: 25px;
}

/* Contact us page styles */

/* Testimonials */
.testimonial__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  margin-top: 24px;
  width: 100%;
  background: rgb(76, 129, 228);
  height: 400px;
  color: #fff;
}

.testimonial__content {
  padding: 20px 100px;
  width: 60%;
  text-align: center;
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  margin: 0 auto;
}

/* About Us Page */

.about__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 80%;
  height: 40%;
  margin: 40px auto;
  margin-top: 20px;
  padding: 4px 100px;
  text-align: center;
  line-height: 24px;
}

.about__container h1 {
  color: blue;
}

.about__container__text {
  margin-top: 20px;
  margin-bottom: 24px;
  padding: 4px 100px;
  text-align: center;
  line-height: 24px;
}

.other__details {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  grid-gap: "24px";
}
.other__details img {
  width: 100%;
  flex: 1;
  height: 700px;
  object-fit: contain;
}

.about__text {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  text-align: left;
  padding: 32px 40px;
}
.about__text p {
  flex: 1;
  text-align: left;
  line-height: 32px;
  align-self: flex-start;
}
.about__text h1 {
  padding: 24px;
}
.about__link {
  width: 200px;
  margin-top: 24px;
  background: rgb(40, 40, 248);
  color: white;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.about__link:hover {
  width: 200px;
  margin-top: 24px;
  background: rgb(24, 24, 238);
  color: rgb(255, 255, 255);
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

/* Contact Page */
.contact__container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80vw;
  height: 100vh;
  margin: 20px auto;
}

.contact__container form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-start;
  justify-content: center;
  grid-gap: 16px;
  width: 85%;
  height: 90vh;
  margin: auto auto;
}
.contact__container form label {
  align-self: flex-start;
  /* margin-left: 150px; */
}
.contact__container form input {
  padding: 24px;
  border: none;
  width: 60%;
  border-radius: 4px;
  align-self: flex-start;
  font-size: 20px;
}
.contact__container form textarea {
  padding: 24px;
  border: none;
  width: 60%;
  height: 50px;
  border-radius: 4px;
  resize: none;
  font-size: 20px;
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.contact__container form input::placeholder {
  font-size: 16px;
}
.contact__container form button {
  padding: 24px;
  border-radius: 4px;
  border: none;
  width: 68%;
  color: #fff;
  font-size: 24px;
  background: blue;
}
.contact__container form button:hover {
  background: rgb(16, 16, 238);
  cursor: pointer;
}

.contact__container__address {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.contact__container__details {
  display: flex;
  width: 50%;
  grid-gap: 20px;
  margin: 20px 0;
}
.contact__container__details a {
  display: flex;
  align-items: flex-start;
  grid-gap: 20px;
  text-decoration: none;
}

.contact__map {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  align-self: flex-start;
}

/* Table Message */

table {
  width: 80vw;
  margin: auto;
}

table tr {
  display: flex;
  align-items: center;
  justify-content: center;
}

table th {
  padding: 10px;
  width: 150px;
  text-align: center;
}
table td {
  padding: 10px;
  width: 150px;
  text-align: center;
}

/* Media Queries */

@media screen and (max-width: 600px) {
  .herosection {
    background: url(../assets/600px.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    height: 40vh;
    padding: 10px;
    justify-items: flex-start;
  }

  .herosection h1 {
    width: 200px;
    font-size: 20px;
    padding-left: 0;
    text-align: left;
  }

  .herosection a {
    width: 120px;
    align-self: flex-start;
    margin-top: 4px;
    padding: 10px;
    font-weight: 600;
    font-size: 14px;
  }

  .about__container {
    grid-gap: 10px;
    margin-top: 20px;
    padding: 0;
    text-align: center;
    line-height: 12px;
  }
  .about__container__text {
    padding: 4px;
    text-align: center;
    line-height: 24px;
  }
  .about__container__text h1 {
    font-size: 22px;
  }

  .about__container h1 {
    color: blue;
    font-size: 24px;
  }

  .about__text p {
    padding-right: 42px;
  }

  .other__details {
    flex-direction: column;
    width: 100%;
    height: 100%;
    grid-gap: "24px";
  }
  .other__details img {
    height: 200px;
    object-fit: contain;
    width: 80%;
  }

  .about__text {
    padding: 10px;
    width: 100%;
  }
  .about__text h1 {
    font-size: 24px;
  }
  .services {
    grid-template-columns: auto !important;
    grid-gap: 32px;
    padding: 16px;
  }

  .service {
    width: 300px;
    height: 250px;
    grid-gap: 4px;
    font-size: medium;
    box-shadow: none;
  }

  .testimonial__content {
    display: none;
  }

  .testimonial__container {
    display: none;
  }

  /* Contact Page */
  .contact__container {
    flex-direction: column-reverse;
    height: 100%;
  }
  .contact__container form {
    grid-gap: 8px;
    width: 100%;
    height: 80%;
    margin: auto auto;
  }

  .contact__container form input,
  textarea {
    padding: 14px;
    border: none;
    width: 92%;
    border-radius: 4px;
    align-self: flex-start;
    font-size: 20px;
  }
  .contact__container form textarea {
    padding: 14px;
    border: none;
    width: 92%;
    height: 50px;
    font-size: 20px;
  }
  .contact__container form button {
    padding: 14px;
    border: none;
    width: 100%;
    border-radius: 4px;
    align-self: flex-start;
    font-size: 20px;
  }

  .contact__container__address {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .services__container h1 {
    color: blue;
    margin-bottom: 16px;
    text-align: center;
    font-size: 24px;
  }
}
@media screen and (max-width: 768px) {
  .herosection {
    background: url(../assets/768px.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 95vw;
    height: 40vh;
    padding: 10px;
    justify-items: flex-start;
  }

  .herosection h1 {
    width: 200px;
    font-size: 20px;
    padding-left: 0;
    text-align: left;
  }

  .herosection a {
    width: 120px;
    align-self: flex-start;
    margin-top: 4px;
    padding: 10px;
    font-weight: 600;
    font-size: 14px;
  }

  .about__container {
    grid-gap: 10px;
    margin-top: 20px;
    padding: 0;
    text-align: center;
    line-height: 12px;
  }
  .about__container__text {
    padding: 4px;
    text-align: center;
    line-height: 24px;
  }
  .about__container__text h1 {
    font-size: 22px;
  }

  .about__container h1 {
    color: blue;
    font-size: 24px;
  }

  .about__text p {
    padding-right: 42px;
  }

  .other__details {
    flex-direction: column;
    width: 100%;
    height: 100%;
    grid-gap: "24px";
  }
  .other__details img {
    height: 200px;
    object-fit: contain;
    width: 80%;
  }

  .about__text {
    padding: 10px;
    width: 100%;
  }
  .about__text h1 {
    font-size: 24px;
  }
  .services {
    grid-template-columns: auto auto;
    grid-gap: 32px;
    padding: 16px;
  }

  .service {
    width: 300px;
    height: 250px;
    grid-gap: 4px;
    font-size: medium;
    box-shadow: none;
  }

  .testimonial__content {
    display: none;
  }

  .testimonial__container {
    display: none;
  }

  /* Contact Page */
  .contact__container {
    flex-direction: column-reverse;
    height: 100%;
  }
  .contact__container form {
    grid-gap: 8px;
    width: 100%;
    height: 80%;
    margin: auto auto;
  }

  .contact__container form input,
  textarea {
    padding: 14px;
    border: none;
    width: 92%;
    border-radius: 4px;
    align-self: flex-start;
    font-size: 20px;
  }
  .contact__container form textarea {
    padding: 14px;
    border: none;
    width: 92%;
    height: 50px;
    font-size: 20px;
  }
  .contact__container form button {
    padding: 14px;
    border: none;
    width: 100%;
    border-radius: 4px;
    align-self: flex-start;
    font-size: 20px;
  }

  .contact__container__address {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .services__container h1 {
    color: blue;
    margin-bottom: 16px;
    text-align: center;
    font-size: 16px;
  }

  body {
    overflow-x: hidden !important;
  }
}
