/* Navigation bar styles */

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  position: fixed;
  top: 0;
  padding: 0.5rem;
  background-color: #fff;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 99;
}
.containerScroll {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  position: fixed;
  top: 0;
  padding: 0.5rem;
  background-color: #000;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 99;
}

.links {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 20px;
  padding: 0 3rem;
}

.logo a {
  text-decoration: none;
  color: #000;
}

.links a {
  color: rgb(23, 26, 184);
  text-decoration: none;
}
.links a:hover {
  font-weight: 500;
  text-decoration: underline;
  font-size: large;
}

.showMenu {
  display: none;
}

@media screen and (max-width: 768px) {
  .links a {
    display: none;
  }

  .showMenu {
    display: flex;
  }
}
@media screen and (max-width: 600px) {
  .links a {
    display: none;
  }
  .showMenu {
    display: flex;
  }
}

/* Single Service */

.single__service {
  width: 100vw;
  height: 95vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.service__heading {
  font-size: 2rem;
  font-weight: 900;
  margin-top: 40px;
  background: url(../assets/bg.jpg) no-repeat;
  background: linear-gradient(
      to right,
      rgba(245, 242, 242, 0.5),
      rgba(0, 0, 0, 0.5)
    ),
    url(../assets/bg.jpg) no-repeat;
  background-size: cover;
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.service__heading h3 {
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 4px;
}

.service__desc {
  padding: 50px;
  line-height: 36px;
}

/* Footer */

.footer {
  width: 100%;
  height: 250px;
  color: #fff;
  background: rgb(76, 129, 228);
  display: flex;
  align-items: center;
  font-size: 14px;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
  padding: 40px 0px;
}
.footer a {
  color: #fff;
}
.footer h3 {
  font-size: 16px;
}

.divider {
  width: 100%;
  border-top: 1px solid #fff;
  margin: auto;
}

.company__details {
  display: flex;
  width: 80%;
  justify-content: space-between;
  height: 80%;
}

.site__credits {
  display: flex;
  justify-content: space-between;
  width: 80%;
}

.company__sitemap,
.contact__details,
.socialmediahandles {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.social__links {
  display: flex;
  grid-gap: 20px;
  width: 100%;
}
.social__links a {
  border-radius: 100%;
  width: 30px;
  height: 30px;
  background: #fff;
  color: rgb(8, 86, 231);
  text-align: center;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  padding-top: 3px;
}
.social__links a:hover {
  background: green;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .footer {
    flex-direction: column;
  }
  .company__sitemap,
  .contact__details,
  .socialmediahandles {
    width: 100%;
    font-size: x-small;
  }

  .socialmediahandles h2 {
    font-size: 14px;
  }
  .social__links {
    /* grid-gap: 10px; */
    align-items: center;
    width: 60%;
    font-size: 12px;
    margin: 0 auto;
  }
  .social__links a {
    /* grid-gap: 10px; */
    font-size: 10px;
    width: 20px;
    height: 20px;
  }
  .company__details {
    flex-direction: column-reverse;
    font-size: smaller;
  }

  .site__credits {
    flex-direction: column;
  }
  .site__credits h3 {
    font-size: 8px;
  }
}

/* About Component */

.about__container__comp {
  width: 100%;
  height: 70%;
}
.about__container__comp h1 {
  color: #fff;
}

/* Counter */
.counter__clients {
  display: flex;
  align-items: center;
  margin: 0 auto;
  justify-content: center;
  width: 100vw;
  height: 60vh;
  text-align: center;
}
.counter__clients h1 {
  color: blue;
  margin-top: 40px;
}

.countup {
  text-align: center;
  font-size: 5rem;
}

.testimonial__content img {
  width: 40%;
  height: 20%;
}

/* Sidebar */

.sidebar__container {
  width: 100vh;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 255, 0.158);
  z-index: 999;
  top: 0;
}
.sidebar__subcontainer {
  width: 0vw;
  height: 100%;
  color: #fff;
  background: rgba(0, 0, 255, 0.774);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 20px;
  animation-name: sidebar;
  animation-duration: 1s;
  animation-fill-mode: both;
  padding: 50px 20px;
}
.sidebar__subcontainer a {
  color: #fff;
  text-decoration: none;
}
.sidebar__subcontainer a:hover {
  text-decoration: underline;
}

@keyframes sidebar {
  from {
    width: -30vw;
  }

  to {
    width: 30vw;
  }
}

@media screen and (max-width: 600px) {
  body {
    overflow-x: hidden;
  }

  .service__heading {
    font-size: 1.5rem;
    font-weight: 500;
    margin-top: 40px;
    background: url(../assets/bg.jpg) no-repeat;
    background: linear-gradient(
        to right,
        rgba(245, 242, 242, 0.5),
        rgba(0, 0, 0, 0.5)
      ),
      url(../assets/bg.jpg) no-repeat;
    padding: 12px;
    background-size: cover;
    width: 100%;
  }

  .container {
    position: fixed;
    top: 0;
    width: 95%;
    z-index: 99;
  }

  .service__desc {
    padding: 20px;
    line-height: 36px;
  }

  .countup {
    text-align: center;
    font-size: 3rem;
  }
}
